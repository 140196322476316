import React from 'react';
import Bluebanner from "../../../components/bluebanner"
import Homeheader from "../../../components/home_header"
import Fullnav from "../../../components/fullnav"
import * as style from "./styles.module.scss"
import Container from '@material-ui/core/Container'
import Drawer from '@material-ui/core/Drawer';
import Slidepanel from "../../../components/slidepanel"
import { Button } from '@material-ui/core';
import mainlogosmall from "../../../images/logo_port_slim_small.svg"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from "gatsby"
import CaseHeader from '../../../components/case_header';
import caseimg from "../../../images/projects/probono.png"
import step1 from "../../../images/projects/probono/step1.svg"
import step1mob from "../../../images/projects/probono/step1_mob.svg"
import step2 from "../../../images/projects/probono/step2.svg"
import step2mob from "../../../images/projects/probono/step2_mob.svg"
import sort from "../../../images/projects/probono/sort.svg"
import sortmob from "../../../images/projects/probono/sortmob.svg"
import journey from "../../../images/projects/probono/journeymap.svg"
import journeymob from "../../../images/projects/probono/journeymap_mob.svg"
import contentstr from "../../../images/projects/probono/content_structure.svg"
import contentstrmob from "../../../images/projects/probono/content_structure_mob.svg"





import probonoUsers from "../../../images/projects/probono/users.png"

import caseimgfull from "../../../images/projects/xmfull.png"
import Sectionheaderdark from "../../../components/typography/section_headers/dark"
import Card from '@material-ui/core/Card';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AlbumIcon from '@material-ui/icons/Album';
import classNames from 'classnames'
import Sectionheaderlight from '../../../components/typography/section_headers/light';
import Remotetabs from "./tabs"
import Footer from '../../../components/footer';
import { StaticImage} from "gatsby-plugin-image"




const Probono = () => {
    return (
        <div >

            <Container className={style.menucontainer} maxWidth={false} disableGutters={true}>
                <Container>
                    <div className={style.onlymobile}>
                        <Fullnav ></Fullnav>
                    </div>
                    <div id="white" className={style.simplenav}>
                        <div className={style.adjuster}>
                            <div className={style.backlink} >
                                <Link to="/"> <ArrowBackIosIcon /> <img src={mainlogosmall} /></Link><span>/</span><span><Link className={style.backto} to="/#past">All Projects</Link></span><span>/</span><span className={style.currentlink}>IA & Interactive Wireframes</span>
                            </div>
                        </div>

                        <Slidepanel></Slidepanel>

                    </div>
                </Container>
            </Container >
            <CaseHeader label="IA & Interactive Wireframes"
                description="This case study is about a pro bono project for a local business owner. The objective of this project revolves around helping the client decide the content structure and Wireframes for redesign and rebranding of existing site  "
                high1="2 sessions of in-person Discovery Workshop"
                high2="Brainstorming ideas for site content and structure"
                high3="Journey maps & Interactive low-fidelity wireframes as final deliverables"
                img={caseimg}

            ></CaseHeader>
            {/* <Container maxWidth={false} disableGutters={true}>
                <Drawer className={style.pf_sidepanel} variant="permanent"></Drawer>
            </Container> */}
            <Container maxWidth={false} disableGutters={true} className={style.darkbg}>
                <Container>
                    <Sectionheaderdark label=" My Role" className={style.pf_all_white_text} ></Sectionheaderdark>
                    <div className={style.cardgrid}>
                        <Card className={style.casecard}>
                            <ul >
                                <li className={style.ligrid}>
                                    <CheckCircleIcon className={style.blue} />Lead
                                </li>
                                <li>
                                    <p>
                                        Lead the workshop and inform clients about the UX process. Bring awareness, ask questions and most importantly - have fun doing it!
                                </p>
                                </li>
                            </ul>
                        </Card>
                        <Card className={style.casecard}>
                            <ul >
                                <li className={style.ligrid}>
                                    <CheckCircleIcon className={style.blue} />Plan, Brainstorm & Facilitate
                                </li>
                                <li>
                                    <p>
                                        Plan the workshop structure and provide insights based on industry standards and best practices. Facilitate the workshop while uncovering important areas based on the session progress
                                </p>
                                </li>
                            </ul>
                        </Card>
                        <Card className={style.casecard}>
                            <ul >
                                <li className={style.ligrid}>
                                    <CheckCircleIcon className={style.blue} />Create
                                </li>
                                <li>
                                    <p>
                                        Create the content structure based on workshop output and provide interactive wireframes to be shared with Squarespace Designers and Developers
                                </p>
                                </li>
                            </ul>
                        </Card>
                    </div>
                </Container>
            </Container>
            <Container className={style.pf_container_pad}>
                <Sectionheaderlight caption="Step 1 " label="Understanding The Landscape" />
                <div className={style.contentgrid}>
                    <p>We started with a quick background on the UX process and ways it can provide a better understanding 
                        about the target users and provide a delightful experience.
                    </p>
                    <p>We began listing down some of the key aspects like target users, their goals, pain points, unique needs etc. 
                       Followed by the business goals and actual problems related to the current website experience.
                        </p>

                    </div>
                
                <div className={style.pf_container_pad}>

                <div className={style.pf_container_pad, style.embedframe}>
                    <img src={step1}/>
                </div>
                <div className={style.pf_container_pad, style.embedframemob}>
                    <img src={step1mob}/>
                </div>
                </div>
                <div className={style.pf_container_pad}>

                <div className={style.pf_container_pad, style.embedframe}>
                    <img src={step2}/>
                </div>
                <div className={style.pf_container_pad, style.embedframemob}>
                    <img src={step2mob}/>
                </div>
                </div>





               
            </Container>
            <Container className={style.pf_container_pad}>
                <Sectionheaderlight caption="Step 2 " label="The Customer Journey" />
                <div className={style.contentgrid}>
                    <p>One of the recurring aspect that came up in the initial step revolved around the current experience and
                        the lack of system capabilities that could be automated. We began listing down the current customer journey to 
                        find areas of improvement and automation.
                    </p>
                    <p>This turned out to be extremely useful for the client, as it provided a high level view of how users discover
                        their products and the steps they need to follow to finally decide and buy. We uncovered key areas that
                        need immediate attention.
                        </p>

                    </div>

                <div className={style.pf_container_pad, style.embedframe}>
                    <img src={journey}/>
                </div>
                <div className={style.pf_container_pad, style.embedframemob}>
                    <img src={journeymob}/>
                </div>

                    





               
            </Container>
            <Container className={style.pf_container_pad}>
                <Sectionheaderlight caption="Step 3 " label="Content Structure" />
                <div className={style.contentgrid}>
                    <p>Keeping in mind (and right in front of us) all the information from the previous steps we started brainstorming
                        on the Information Architecture. We began with a simple question - What features can add value to the users and
                        the business?
                    </p>
                    <p>All the features were then revisited to align with the target users and their needs. We later grouped features into
                        clusters of information which then was labelled. The labels became the main pages and key features of the overall
                        site experience.
                        </p>

                    </div>

                <div className={style.pf_container_pad, style.embedframe}>
                    <img src={sort}/>
                </div>
                <div className={style.pf_container_pad, style.embedframemob}>
                    <img src={sortmob}/>
                </div>
                <div className={style.pf_container_pad}>
                <div className={style.pf_container_pad, style.embedframe}>
                    <img src={contentstr}/>
                </div>
                <div className={style.pf_container_pad, style.embedframemob}>
                    <img src={contentstrmob}/>
                </div>
                </div>


                    





               
            </Container>
            <Container className={classNames(style.greyblock, style.pf_container_pad)} maxWidth={false} disableGutters={true}>
                <Container>
                    <Sectionheaderlight caption="Step 4" label="The Interactive Mocks" />
                    <div className={style.contentgrid}>
                    <p>One of the main deliverables of this project are the interactive wireframes, that could be used by the visual designers & developers
                        of Squarespace to build the site. A low fidelity wireframe was built and reviewed later with the clients for feedback and
                        changes.
                    </p>
                    <p>The wireframes were then shared with the team that is currently working on the site. Few features and their interactions
                        had to be changed because of the platform dependency,templates and components, but without changing the overall
                        experience captured in the discovery session.

                        </p>

                    </div>
                    <div className={style.pf_container_pad}>
                        <iframe className={style.iframe} width="100%" height="700" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FdgDjqmLN4qR3n81Y9lVVUL%2F3S-wireframe%3Fnode-id%3D2%253A2%26starting-point-node-id%3D2%253A2%26scaling%3Dscale-down-width" allowfullscreen></iframe>

                    </div>
                   

                </Container>
               


            </Container>
         
<Footer/>

        </div>

    )
}


export default Probono


